<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席第二届HELLO FUTURE.新生代创新大会
              </p>
              <div class="fst-italic mb-2">2024年4月28日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >创新大会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/80/01-创新大会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    4月25日，第二届HELLO FUTURE.新生代创新大会在北京成功举行，非凸科技合伙人朱为受邀出席并发表主题演讲，探讨了科技创新赋能投资的新趋势，展现了新生代力量在行业变革中的重要作用。同时，非凸科技荣获GOLDEN TURBO“年度最具成长力创新项目”奖。
                  </p>
                  <p>
                    此次大会聚焦创新领域，旨在发现并连接正在崛起的新生代公司，以及引领行业发展变革的创新领袖。这不仅为参与者提供了一个展示和学习的平台，更是搭建了一个与全球创新思潮同步的桥梁。活动上，行业专家分享了各自领域的AI创新应用以及前沿科技的趋势洞见，为在场的创业者和投资者提供了宝贵参考。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/80/02-朱为.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，大模型、大数据、云计算等技术的创新与应用，逐渐成为新形势下金融行业核心业务转变的核心变量。朱为表示，基于新兴的数智技术，非凸科技在算法端、交易端、投研端以及交易产业链生态构建等方面做了进一步迭代与升级，从技术和业务层面更好地赋能机构投资者与个人投资者。未来，非凸科技将继续致力于科技创新与金融场景的深度应用，吸引和汇聚更多金融主体和其他行业的优质资源，推动市场多元发展，不断壮大开放创新、合作共赢的产业生态，进而实现各方共同成长。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/80/03-奖项.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    此次荣获创新奖项，将激励着非凸科技更加坚定前行的方向，始终坚持核心技术自主创新，为资本市场发展注入更多的活力。也期待与更多志同道合的伙伴，共同探索数智交易的未来。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News80",
};
</script>

<style></style>
